<template>
  <div class="salarySiginPassword">
    <van-nav-bar
      :title="$t('salarySiginPassword.sspTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :fixed="true"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <div class="content">
      <div class="inputPassword">{{ $t("salarySiginPassword.sspTxt2") }}</div>
      <div class="inputPasswordGrid">
        <van-password-input
          :value="value"
          :gutter="10"
          :focused="showKeyboard"
          :error-info="errorInfo"
          @focus="showKeyboard = true"
        />
        <van-number-keyboard
          v-model="value"
          extra-key="X"
          :show="showKeyboard"
          @blur="showKeyboard = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { checkSalaryPwd } from "@api/wx.js";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      userInfo,
      showKeyboard: false,
      errorInfo: "",
      value: "",
      leftToPath: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from.fullPath, '1111111')
    next((vm) => {
      if (from.fullPath != "/AscLogin") {
        vm.leftToPath = from.fullPath;
      } else {
        location.href = localStorage.url;
      }
    });
  },
  watch: {
    value(value) {
      if (value.length == 6) {
        this.getData(value);
      }
    },
  },
  created() {},
  methods: {
    onClickLeft() {
      if (this.leftToPath) {
        this.$router.push("/home");
      } else {
        // 外站进入的地址
        const url = localStorage.getItem("url");
      }
    },
    onClickRight() {},
    getData(val) {
      checkSalaryPwd({
        empid: this.userInfo.empid,
        salarypwd: val,
      }).then((res) => {
        console.log(res);
        if (res.data[0].isok) {
          this.$router.push("/salarySiginList");
        } else {
          this.errorInfo = this.$t("salarySiginPassword.sspTxt3");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    .inputPassword {
      width: 100%;
      text-align: center;
      font-size: 36px;
      margin-top: 180px;
      margin-bottom: 78px;
    }
  }
}
</style>